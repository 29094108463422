import moment from "moment";
function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}

export function CommonDateFormat(date, format = "YYYY-mm-dd HH:MM:SS") {
    return dateFormat(format, date)
}
//mouth 0 本月 -1 上一个月  1 下一个月
export function getDateFormatMouth(dateTime, mouth, format = "YYYY-MM-DD HH:mm:ss") {
    let start = new Date(dateTime);
    return moment(start).add(mouth, 'M').format(format)
}
//
export function getDateTime(dateTime, format = "YYYY-MM-DD HH:mm:ss") {
    let start = new Date(dateTime);
    return moment(start).format(format)
}

//返回两个日期之间的日期
export function getDatesByBetweenDate(starDay, endDay) {
    var arr = [];
    var dates = [];

    // 设置两个日期UTC时间
    var db = new Date(starDay);
    var de = new Date(endDay);

    // 获取两个日期GTM时间
    var s = db.getTime() - 24 * 60 * 60 * 1000;
    var d = de.getTime() - 24 * 60 * 60 * 1000;

    // 获取到两个日期之间的每一天的毫秒数
    for (var i = s; i <= d;) {
        i = i + 24 * 60 * 60 * 1000;
        arr.push(parseInt(i))
    }

    // 获取每一天的时间  YY-MM-DD
    for (var j in arr) {
        var time = new Date(arr[j]);
        var year = time.getFullYear(time);
        var mouth = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : ('0' + (time.getMonth() + 1));
        var day = time.getDate() >= 10 ? time.getDate() : ('0' + time.getDate());
        var YYMMDD = year + '-' + mouth + '-' + day;
        dates.push(YYMMDD)
    }
    return dates;
}